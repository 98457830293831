.twoDMain {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	overflow: hidden;
	height: calc(100vh - 50px);
	min-height: 600px;
}

.twoDGameContainer {
	display: flex;
	flex: 1;
	max-height: 100%;
	overflow: hidden;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.twoDBoard {
	display: flex;
	position: relative;
	max-height: min(268px, 60%) !important;
}

.twoDBoard > img {
	max-width: 100%;
	max-height: 100%;
	z-index: 10;
}

.twoDPiece {
	position: absolute;
}
.twoDPiece > img {
	max-height: 100%;
	max-width: 100%;
}

.p1 {
	height: min(86px, 32.08%);
	bottom: min(13px, 4.849%);
	left: max(-81px, -42.555%);
}
.p2 {
	height: min(163.98px, 61.186%);
	width: min(127.18px, 66.817%);
	bottom: max(-48px, -17.91%);
	left: min(70px, 36.776%);
}
.p3 {
	height: min(184.93px, 69.003%);
	width: min(95.18px, 50.005%);
	bottom: min(116px, 43.283%);
	left: min(145px, 76.179%);
}
.p4 {
	height: min(99.16px, 37%);
	width: min(175.56px, 92.234%);
	bottom: -70px;
	left: 25px;
	bottom: max(-70px, -26.119%);
	left: min(25px, 13.134%);
}

.twoDBottomContainer {
	display: flex;
	flex-direction: column;
}

.twoDNameContainer {
	text-align: center;
	margin-bottom: 40px;
	font-family: Impact;
	font-size: 16px;
}

.twoDValidateButton {
	z-index: 10;
	position: relative;
	cursor: not-allowed;
	display: flex;
	height: 52px;
	max-height: 52px;
	overflow: hidden;
	padding: unset;
	align-self: center;
	align-items: center;
	border: none;
	background-color: #444444;
	border-radius: 26px;

	font-family: Impact;
	font-size: 16px;
	color: #FFFFFF;
}
.twoDValidateButton[data-active="true"] {
	padding-right: 67px;
	padding-left: 24px;
	box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.45);
}
.twoDValidateButton[data-active="true"]:hover {
	cursor: pointer;
	opacity: 0.8;
}

.twoDValidateButton[data-active="false"] > .twoDValidateText {
	display: none;
}

.twoDValidateIcon {
	height: 52px;
	width: 52px;
	background-color: #2B2B2B;
	border-radius: 50%;
}
.twoDValidateButton[data-active="true"] > .twoDValidateIcon {
	position: absolute;
	top: -2px;
	right: -2px;
	height: 56px;
	width: 56px;
}

.twoDValidateIcon > div {
	display: flex;
	height: 51px;
	width: 51px;
	justify-content: center;
	align-items: center;
	margin-top: 3px;
	margin-left: 3px;
	border-radius: 50%;
	background-color: #444444;
	box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.45);
}

.twoDPieceContainer {
	display: flex;
	max-height: min(127px, 11vh);
	background-color: #444444;
	margin: 16px 32px 32px 32px;
	border-radius: 8px;
	align-items: center;
}

.twoDPieceContainer > .init {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 33%;
	height: 80%;
}
.twoDPieceContainer > .init > img {
	max-height: 100%;
	max-width: 90%;
}

.init.row {
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.init.init.row > .init {
	display: flex;
	align-items: center;
	justify-content: center;
}
.init.row > div:first-of-type {
	height: 65%;
}
.init.row > div:last-of-type {
	height: 35%;
}
.init.row img {
	max-height: 100%;
	max-width: 100%;
	object-fit: contain;
	object-position: center;
}

.twoDModal {
	z-index: 999;
	position: fixed;
	top: 50%;
	left: 50%;
	width: calc(100% - 48px);
	max-width: 382px;
	transform: translate(-50%, -50%);
	background-color: #444444;
}

.twoDModalIcon {
	cursor: pointer;
	position: absolute;
	padding: 10px;
	top: 0px;
	right: 0px;
}

.twoDModal > div {
	display: flex;
	flex-direction: column;
	gap: 30px;
	margin: 32px;
}

@media (min-width: 1024px) {
	.twoDMain {
		min-height: 800px;
	}

	.twoDMain > .titleContainer {
		position: absolute;
		top: 216px;
	}

	.twoDBoard {
		top: 10%;
		max-height: min(268px, 50%) !important;
	}

	.twoDNameContainer {
		font-size: 24px;
	}

	/* .twoDValidateButton {
		height: 106px;
		max-height: 106px;
		border-radius: 53px;

		font-size: 30px;
	}
	.twoDValidateButton[data-active="true"] {
		padding-right: 154px;
		padding-left: 48px;
	}

	.twoDValidateIcon {
		height: 106px;
		width: 106px;
	}
	.twoDValidateButton[data-active="true"] > .twoDValidateIcon {
		height: 110px;
		width: 110px;
	}

	.twoDValidateIcon > div {
		height: 103px;
		width: 103px;
	}

	.twoDValidateIcon > div > img {
		height: 34px;
	} */

	.twoDPieceContainer {
		position: absolute;
		display: flex;
		flex-direction: column;
		/* width: 260px; */
		width: 200px;
		top: 216px;
		right: 24px;
		margin: unset;
		max-height: unset;
		height: calc(100% - 216px);
	}

	.twoDPieceContainer > .init {
		flex: 1;
		width: 100%;
		overflow: hidden;
		margin: 32px 0;
	}
	.twoDPieceContainer > .init:nth-child(2) {
		margin: unset;
	}
	.twoDPieceContainer > .init > img {
		max-width: 100%;
	}

	.init.row {
		flex-direction: column;
		display: flex;
		overflow: hidden;
	}
	.init.row > div {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
	}
	.init.row > div:first-of-type > img {
		width: 45px;
	}
	.init.row > div:last-of-type > img {
		width: 80px;
	}

	.twoDModal {
		position: absolute;
		/* width: 475px; */
		left: 0px;
		top: 380px;
		transform: unset;
		background: unset;
	}

	.twoDModal > div {
		width: 400px;
	}

	.twoDModalIcon {
		display: none;
	}
}

@media (min-width: 1280px) {
	.twoDNameContainer {
		position: absolute;
		margin: unset;
		top: 60%;
		left: 62%;
	}

	.twoDPieceContainer {
		width: 260px;
	}

	.twoDModal > div {
		width: 475px;
	}
}