.referenceFooterMain {
	display: flex;
	flex-direction: column;
	margin: 16px 16px 32px 16px;
}

.referenceFooterTextContainer > div:not(:last-of-type) {
	margin-bottom: 16px;
}

.referenceFooterMain p {
	font-family: Arial;
	font-size: 12px;
	color: white;
	margin: unset;
}

.referenceFooterMain a,
.referenceMain a {
	color: white;
	text-decoration: unset;
}

.referenceFooterImage,
.referenceImage {
	width: 107px;
	height: 40px;
	margin-top: 32px;
}
.referenceImage {
	margin: 32px;
}

.referenceMain {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: calc(100vh - 50px);
}

.referenceTextContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin: 32px;

	font-family: Arial;
	font-size: 16px;
	color: #FFFFFF;
}

.referenceTextContainer p {
	margin: unset;
}
.referenceTextContainer > div:not(:last-of-type) {
	margin-bottom: 16px;
}

@media (max-width: 430px) {
	.referenceTextContainer {
		margin: 32px 8px 32px 32px;
		font-size: 12px;
	}
}

@media (min-width: 1024px) {
	.referenceMain {
		min-height: 100vh;
	}

	.referenceFooterMain {
		flex-direction: row-reverse;
		align-self: flex-start;
		flex: 1;
		margin: 32px 0;
		width: 100%;
	}

	.referenceFooterImage,
	.referenceImage {
		width: 260px;
		height: 97px;
		margin-left: 32px;
		margin-top: 0;
	}

	.referenceFooterTextContainer {
		position: relative;
		display: flex;
		width: 100%;
		flex-direction: column;
		margin-left: 138px;
		padding-left: 32px;
		border-left: 1px solid #FFFFFF;
	}

	.referenceFooterTextContainer > div:last-of-type {
		position: absolute;
		right: 32px;
		bottom: 0;
		font-size: 16px;
	}

	.referenceTextContainer {
		margin-top: 40px;

		font-size: 24px;
	}
}