.threeDMain {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: calc(100vh - 50px);
}

.threeDGameContainer {
	position: relative;
	height: max(45vh, 300px);
	min-height: max(45vh, 300px);
}

.threeDClick {
	cursor: pointer;
	position: absolute;
	width: 16px;
	height: 16px;
	background-color: #444444;
	border: 1px solid #FFFFFF;
}
.threeDClick[data-actual="true"] {
	background-color: #FFBF9F;
}

.threeDClick[data-id="1"] {
	top: 25%;
	left: calc(50% + 10vh);
}
.threeDClick[data-id="2"] {
	bottom: 25%;
	left: calc(50% - 2vh);
}

.threeDBottomContainer {
	display: flex;
	flex-direction: column;
	margin: 0 32px 32px 32px;
	max-width: 366px;
	align-self: center;
}

.threDRotateText {
	display: flex;
	flex-direction: column;
	align-items: center;

	font-family: Arial;
	font-size: 16px;
	color: white;
	text-align: center;
}

.threeDCanvas,
.threeDCanvas * {
	display: flex !important;
	flex: 1 !important;
	height: max(45vh, 300px) !important;
	min-height: max(45vh, 300px) !important;
}

@media (min-width: 1024px) {
	.threeDGameContainer,
	.threeDCanvas,
	.threeDCanvas * {
		height: 60vh !important;
		min-height: 60vh !important;
	}

	.threeDClick[data-id="1"] {
		left: calc(50% + 15vh);
	}

	.threDRotateText > .textContainer > p {
		text-align: left;
		width: 411px !important;
	}

	.threDRotateText > .textContainer {
		top: 350px;
		left: 24px;
		position: absolute;
		max-width: 350px;
	}
}

@media (min-width: 1280px) {
	.threDRotateText > .textContainer {
		max-width: unset;
	}
}