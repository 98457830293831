
.textContainer {
	position: relative;
	border: 1px solid #FFFFFF;
	padding: 25.2px 35px;
	color: #FFFFFF;
}
.textContainer[data-color="yellow"] {
	color: #FCB813;
	border-color: #FCB813;
}
.textContainer[data-color="red"] {
	color: #F36C87;
	border-color: #F36C87;
}

.textContainer > div {
	width: fit-content;
	margin-top: -35px;
	margin-left: -10px;
	max-width: 215px;
	padding: 0 10px;
	background-color: #444444;
}
.textContainer h3 {
	font-family: Impact;
	font-size: 16px;
	margin: 0;
}

.textContainer p {
	font-family: Arial;
	font-size: 16px;
	margin: 0;
	margin-top: 16px;
}

@media (max-width: 385px) {
	.textContainer p {
		font-size: 14px;
	}
}

@media (min-width: 1024px) {
	.textContainer > div {
		background-color: #2B2B2B;
		transform: translate(0, -20%);
		max-width: 293px;
	}

	.textContainer h3,
	.textContainer p {
		font-size: 20px;
	}
}

@media (min-width: 1280px) {
	.textContainer h3,
	.textContainer p {
		font-size: 24px;
	}
}