.headerMain {
	display: flex;
	/* width: 100%; */
	margin: 24px 24px 16px 32px;
	justify-content: space-between;
	align-items: center;
}

.headerLogo {
	cursor: pointer;
	height: 21px;
}

.headerIcon {
	display: flex;
	height: 56px;
	width: 56px;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.45);
	background-color: #444444;
}
.headerIcon:hover {
	cursor: pointer;
	opacity: 0.8;
}
.headerIcon > img {
	height: 30px;
}

@media (min-width: 768px) {
	.headerMain {
		margin-top: 40px;
	}

	.headerLogo {
		height: 80px;
	}

	.headerIcon {
		height: 96px;
		width: 96px;
	}
	.headerIcon > img {
		height: 52px;
		width: 52px;
	}
}