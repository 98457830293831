.homeMain {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: calc(100vh - 50px);
	justify-content: center;
	align-items: center;
}

.homeCenter {
	margin-top: -50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: min(80%, 760px);
}
.homeCenter[data-view="home"] {
	max-width: min(85%, 755px);
	width: 100%;
}

.homeTitle {
	margin: 0;
	margin-bottom: 24px;
	font-family: Impact;
	font-size: 24px;
	text-align: center;
	color: white;
}

.homeImage {
	width: 100%;
}

.homeImageSobi {
	position: absolute;
	bottom: 16px;
}

.homeButton {
	position: absolute;
	bottom: 55px;
	height: 49px;
	width: 318px;
	border: unset;
	border-radius: 25px;
	background-color: #606060;
	box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.45);

	font-family: Arial;
	font-size: 16px;
	font-weight: bold;
	color: #FFFFFF;
}
.homeButton:hover {
	cursor: pointer;
	opacity: 0.8;
}

@media (min-width: 768px) {
	.homeCenter {
		margin: unset;
	}
}

@media only screen and (max-width: 767px),
		(max-height: 767px) and (orientation: landscape) {
	.homeCenter {
		margin-top: -50px;
	}
	.homeButton {
		bottom: 20px;
	}
}

@media (min-width: 1024px) {
	.homeImage {
		height: 300px;
	}
	.homeCenter[data-view="home"] .homeImage {
		height: 160px;
	}

	.homeImageSobi {
		height: 98px;
		width: 260px;
	}

	.homeTitle {
		max-width: 550px;
		font-size: 48px;
	}

	.homeButton {
		bottom: unset;
		position: relative;
		margin-top: 140px;
	}
}