.main {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 100vw;
	max-width: 1640px;
	margin: auto;
	min-height: calc(100vh - 50px);
	height: max-content;
}

.popupModal {
	z-index: 999;
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	top: 50%;
	left: 50%;
	width: calc(100% - 48px);
	max-width: 382px;
	transform: translate(-50%, -50%);
	background-color: #444444;
}

.popupModal > img {
	margin-top: 32px;
	margin-bottom: 8px;
}

.popupModalText {
	font-family: Arial;
	font-size: 16px;
	color: white;
	text-align: center;
}

.popupModalButtonContainer {
	display: flex;
	margin: 8px 32px 32px 32px;
	gap: 16px;
}

.popupModalButton {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 49px;
	width: 100px;
	border: unset;
	border-radius: 25px;
	background-color: #606060;
	box-shadow: -1px -1px 1px rgba(255, 255, 255, 0.45);

	font-family: Arial;
	font-size: 16px;
	font-weight: bold;
	color: #FFFFFF;
}
.popupModalButton:hover {
	cursor: pointer;
	opacity: 0.8;
}