.titleContainer {
	display: flex;
	flex-direction: column;
	margin-left: 32px;
	padding: 0 16px;
	border-left: 4px solid #FFC09F;
}
.titleContainer > h1,
.titleContainer > h2 {
	margin: 0;
	font-family: Impact;
	color: #FFC09F;
}
.titleContainer > h1 {
	font-size: 24px;
}
.titleContainer > h2 {
	margin-top: 8px;
	font-size: 16px;
}

@media (min-width: 1024px) {
	.titleContainer > h1 {
		font-size: 48px;
	}
	.titleContainer > h2 {
		font-size: 24px;
	}
}